.dashboard-main-side-bar-open {
  padding: 12px 0px 30px 10px;
}
.dashboard-main {
  padding: 12px 50px 30px 10px;
  background: radial-gradient(
    44.06% 56.06% at 50% 55.27%,
    #000000 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
  background-blend-mode: lighten;
}
.dashboard-container-img {
  position: relative;
  width: 100%;
}
.dashboard-container-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 100%;
  background-image: url("../../../../assets/images/world-map-red.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.dashboard-container-img-red::before {
  background-image: url("../../../../assets/images/world-map-red.png");
}
.dashboard-container-img-green::before {
  background-image: url("../../../../assets/images/world-map-green.png");
}
.dashboard-container-img-yellow::before {
  background-image: url("../../../../assets/images/world-map-yellow.png");
}
.dashboard-container-img-blue::before {
  background-image: url("../../../../assets/images/world-map-blue.png");
}

.dashboard-main.red {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #3b0810 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}

.dashboard-main.yellow {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #47290c 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}

.dashboard-main.blue {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #193861 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}

.dashboard-main.green {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #13370f 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}
.dashboard-main-side-bar-open.red {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #3b0810 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}

.dashboard-main-side-bar-open.yellow {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #47290c 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}

.dashboard-main-side-bar-open.blue {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #193861 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}

.dashboard-main-side-bar-open.green {
  background: radial-gradient(
    50.06% 66.06% at 50% 53.27%,
    #13370f 0%,
    rgba(104, 156, 208, 0) 74.08%
  );
}

.dashboard-container {
  display: flex;
  padding: 1rem 1rem 1rem 1rem;
  color: white;
  position: relative;
  justify-content: space-between;
}

@media (max-width: 1700px) {
  .stats-container {
    width: 47% !important;
  }
}
@media (max-width: 1700px) {
  .dashboard-left,
  .dashboard-right {
    width: 360px !important;
  }
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    width: 315px !important;
  }
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    .stats-card-side-bar-open {
      padding: 0px 40px 20px !important;
    }
    .risk-center-text .top {
      font-size: 90.49px;
    }
    .risk-center-text .middle {
      font-size: 32.19px;
    }
    .risk-center-text .bottom {
      font-size: 12.1px;
    }
    .risk-center-card {
      bottom: 84px;
    }
  }
  .risk-center-card {
    max-width: 410px;
  }
  .card-left {
    width: 52% !important;
  }
  .card-middle {
    right: 38.3% !important;
    top: 5px !important;
  }
  .card-right {
    width: 38.4% !important;
    top: 10px !important;
  }
  .radar-chart-container {
    .risk-score-trend-container {
      .card-main {
        .card-left {
          width: 60% !important;
          top: 1px !important;
        }
        .card-middle {
          width: 7% !important;
          right: 33.2% !important;
          top: 4px !important;
        }
        .card-right {
          width: 33.3% !important;
          top: 8px !important;
        }
      }
    }
  }
  .generic-title {
    font-size: 22px !important;
  }
  .stats-number {
    font-size: 25px !important;
    line-height: 32px !important;
    sup {
      font-size: 12px !important;
      line-height: 24.15px !important;
    }
  }
}

.dashboard-left,
.dashboard-right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 450px;
}
.dashboard-left-side-bar-open,
.dashboard-right-side-bar-open {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 400px;
}
.highlight-scan-title {
  font-family: "Bank Gothic Pro", Arial, sans-serif;
  font-size: 21.16px;
  font-weight: 500;
  line-height: 25.39px;
  text-align: center;
  padding: 82px 0 122px 0px;
  color: var(--Armory-Gris-Semiaprobado, #818282);
  text-decoration-skip-ink: none;
}
.line-scan-chart {
  font-family: "Bank Gothic Pro", Arial, sans-serif;
  font-size: 21.16px;
  font-weight: 500;
  line-height: 25.39px;
  text-align: center;
  padding: 62px 0 102px 0;
  color: var(--Armory-Gris-Semiaprobado, #818282);
  text-decoration-skip-ink: none;
}
.security-scan-chart {
  font-family: "Bank Gothic Pro", Arial, sans-serif;
  font-size: 21.16px;
  font-weight: 500;
  line-height: 25.39px;
  text-align: center;
  padding: 62px 0 102px 0;
  color: var(--Armory-Gris-Semiaprobado, #818282);
  text-decoration-skip-ink: none;
}

.dashboard-center {
  display: flex;
  justify-content: center;
  align-items: end;
  position: absolute;
  bottom: -27px;
  left: 0px;
  width: 100%;
  background: radial-gradient(
    67.06% 34.06% at 51% 79.27%,
    rgb(9, 29, 46) 42%,
    rgba(24, 53, 79, 0) 77.08%,
    rgba(44, 66, 89, 0) 97.5%
  );
}
.top-card {
  height: 240px;
}
.middle-card {
  height: 300px;
}

.radar-chart {
  height: 230px !important;
  padding-bottom: 35px !important;
  padding-top: 18px;
}
.risk-center-card {
  position: absolute;
  bottom: 90px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  transition: height 0.3s ease-in-out;
}

.risk-center-card.scanning-mode {
  height: 450px;
}

.risk-center-card.normal-mode {
  height: 400px;
}

.risk-center-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  z-index: 0;
}

.risk-center-text {
  z-index: 0;
  color: white;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.risk-center-text .top {
  font-size: 100.49px;
  font-weight: 700;
  font-family: "Bank Gothic Pro", Arial, sans-serif;
  line-height: 80px;
}

.risk-center-text .scanning-animation-position {
  margin-bottom: -20px;
  margin-top: -90px;
}

.risk-center-text .middle {
  font-size: 40.19px;
  font-weight: 500;
  text-align: center;
  font-family: "Bank Gothic Pro", Arial, sans-serif;
  margin: 0px !important;
}
.risk-center-text .bottom {
  font-size: 17.1px;
  font-weight: 300;
  line-height: 20.52px;
  font-family: "Bank Gothic Pro", Arial, sans-serif;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.top-bar {
  height: 10px;
  position: relative;
}

.card-left {
  width: 45%;
  background: #ffffff;
  box-shadow: linear-gradient(180deg, #1a1a1a, #000000);
  height: 2px;
  top: 1px;
  left: 0px;
  position: absolute;
  border-radius: 0px 10px 10px 0px;
}
.card-main {
  position: absolute;
  left: 0;
  right: 0;
  top: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-middle {
  width: 10%;
  background: #ffffff;
  transform: rotate(15deg);
  height: 2px;
  position: absolute;
  right: 45.4%;
  top: 7px;
}

.card-right {
  width: 45.9%;
  background: #ffffff;
  height: 2px;
  position: absolute;
  top: 13.3px;
  right: 0px;
  border-radius: 10px 0px 0px 10px;
}

.progress-bar-dashboard {
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 5.71px 0px #ffffff;
  top: 0px;
  left: 15px;
  height: 4px;
  width: 20%;
  background: white;
}
.risk-score-trend-container {
  color: white;
  font-family: Arial, sans-serif;
  position: relative;
  max-width: 480px;
  margin: 0 auto;
  width: 100%;
}

.generic-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: lighten;
  z-index: 1;
}
.chart-bar {
  height: 236px;
}

.title {
  display: flex;
  align-items: center;
  padding: 9px 15px 20px 15px;
  margin-top: 11px;
}

.generic-title {
  font-weight: bold;
  font-weight: 500;
  font-family: "Bank Gothic Pro", Arial, sans-serif;
  margin: 0;
  font-size: 24px;
}

.line-chart-container {
  position: relative;
}

.chart {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
}

.chart circle {
  stroke: white;
  stroke-width: 0.5;
}

.dates {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #818282;
}

.attack-main {
  padding: 0px 20px 20px;
  height: 230px;
}

.attack-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Inter";
}

.attack-card {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.attack-container span {
  text-decoration: underline;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  cursor: pointer;
  color: #d3d3d3;
}
.SecurityIssues-main-card {
  color: white;
  width: 100%;
  border: none;
  padding: 0px 43px 53px 43px;
  background: transparent;
}
.lottie-main {
  width: 50%;
  height: 50%;
}

.stats-main {
  height: 190px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 25px 20px;
  gap: 0px;
}

.stats-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats-card-side-bar-open {
  height: 190px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0px 75px 20px !important;
  .stats-container {
    width: 47%;
  }
}

.stats-number {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  font-family: "Inter";
}

sup {
  font-size: 15px;
  font-weight: 700;
  line-height: 18.15px;
  text-align: center;
  vertical-align: text-bottom;
  margin-left: 3px;
}

sup.positive {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
}

sup.negative {
  text-decoration: none;
}

.stats-label {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  color: #d3d3d3;
}

.line-chart-offensive {
  height: 190px !important;
  width: 100% !important;
}

.chart-label {
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.radar-chart-labels-container {
  display: flex;
  align-items: center;
  .radar-chart-labels-value {
    height: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    text-align: right;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .radar-chart-labels-additional {
    width: 20px;
    height: 18px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #d3d3d3;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 4px;
  }
}
.radar-chart-labels {
  text-decoration: underline;
  width: 49px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #d3d3d3;
}

@media (min-width: 1900px) {
  .dashboard-left,
  .dashboard-right {
    width: 550px !important;
  }
}

@media (min-width: 2300px) {
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    width: 550px !important;
  }
}

@media (max-width: 2300px) {
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    width: 500px !important;
  }
}
@media (max-width: 2100px) {
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    width: 450px !important;
  }
}
@media (max-width: 1900px) {
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    width: 400px !important;
  }
}
@media (max-width: 1720px) {
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    width: 380px !important;
  }
}

@media (max-width: 1580px) {
  .dashboard-left-side-bar-open,
  .dashboard-right-side-bar-open {
    width: 345px !important;
  }
}

@media (max-width: 1660px) {
  .risk-center-text {
    .top {
      font-size: 80px !important;
    }
    .middle {
      font-size: 33px;
    }
    .bottom {
      font-size: 15px;
    }
  }

  .dashoard-animation-player {
    width: 500px !important;
    height: 500px !important;
  }
}

// @media (min-width: 1400px) and (max-width: 1920px) {
//   .dashboard-container-img {
//     height: 82vh !important;
//   }

//   .dashboard-background-col {
//     height: 82vh !important;
//   }
//   .dashboard-container {
//     height: 82vh !important;
//     .generic-container {
//       height: 215px !important;
//       .generic-title {
//         font-size: 21px !important;
//       }
//     }
//   }
//   .line-chart-offensive {
//     height: 145px !important;
//   }
//   .radar-chart {
//     height: 160px !important;
//     canvas {
//       margin-top: -6px;
//     }
//   }
//   .stats-card-side-bar-open {
//     height: 150px !important;
//   }
//   .attack-container {
//     margin-bottom: 3px !important;
//     span {
//       font-size: 18px !important;
//     }
//   }
// }
