.globe-easm {
    background: #FFFFFF40;
    height: 133px;
    width: 133px;
    border-radius: 50%;
  }
  .custom-checkbox {
    width: 16px;
    height: 16px;
    background: none;
    border: 2px solid #F5F5F5;
    border-radius: 2px;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none;
    position: relative;
    cursor: pointer;
}

.custom-checkbox:checked {
    background-color: #398FFE;
    border: 2px solid #398FFE;
}

.custom-checkbox:hover {
    border: 2px solid #398FFE;
}

.custom-checkbox:checked::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2.4px 2.4px 0;
    transform: rotate(45deg);
}

.custom-checkbox:disabled {
    background-color: #767272;
    border-color: #767272;
    cursor: not-allowed;
}

.custom-checkbox:disabled:checked {
    background-color: #767272;
    border-color: #767272;
    ;
}



.sticky-action {
    position: sticky;
    right: 0;
    border-left: 2px solid #9F9FA4;
    padding-right: 15px;
}

.issue-checkbox{
    margin-bottom: 13px;
    margin-right: 7px !important;
}
.issue-check-box{
margin-right: 4px;
margin-top: 2rem;
}
.security-checkbox {
    width: 12px;
    height: 12px;
    background: none;
    border: 2px solid #A7A7B1;
    border-radius: 2px;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    

}
.security-checkbox:checked {
    border: none;
}
.security-checkbox:checked::before {
    content: url('../../../images/blue-checkbox.png');
}



.Toastify__toast-body{ 
   margin-top: 0px !important;
   padding-top: 0px !important;
    align-items: start !important;
}
.Toastify__progress-bar {
    top: 0 !important;

}
.Toastify__toast{
    padding-top: 15px !important;
    font-family: "Kodchasan";
    }

    .toast-text{
     font-family: "Kodchasan" !important;
    color: #3B3B3E !important;
    }
    
    .fw-600{
    font-weight: 600;
   
    } .fs-12{
    font-size: 12px;
    }
    .Toastify__toast-theme--light {
     color: #3B3B3E !important;
}

.custom-base-checkbox{
    .form-check-input {
        width: 16px;
        height: 16px;
        background: none;
        border: 2px solid #F5F5F5;
        border-radius: 2px;
        appearance: none; 
        -webkit-appearance: none; 
        -moz-appearance: none;
        position: relative;
        cursor: pointer;
    }
    
    .form-check-input:checked {
        background-color: #398FFE;
        border: 2px solid #398FFE;
    }
    
    .form-check-input:hover {
        border: 2px solid #398FFE;
    }
    
    .form-check-input:checked::before {
        content: "";
        position: absolute;
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 2.4px 2.4px 0;
        transform: rotate(45deg);
    }
    
    .form-check-input:disabled {
        background-color: #767272;
        border-color: #767272;
        cursor: not-allowed;
    }
    
    .form-check-input:disabled:checked {
        background-color: #767272;
        border-color: #767272;
        ;
    }
    .form-check-input:focus {
        box-shadow: none;
    }
    
}
.email-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 270px;
    cursor: pointer;
  }
  