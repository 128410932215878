.sub-sidebar {
  border: none !important;
}

.custom-sidebar {
  height: calc(100vh - 60px);
  background-color: #100e10;
  overflow-y: auto;
  color: #fff;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sidebar-top-heading {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.sidebar-sub-heading {
  span {
    font-size: 18px;
    font-weight: 700;
    padding: 0px 22px;
    text-align: left;
  }
}

.custom-list-group {
  width: 20vw;
  top: 25px;
  left: 20px;
  padding: 8px 0px 0px 0px;
  gap: 24px;
}

.custom-list-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 0.5rem 0.5rem;
}

.custom-list-item span {
  margin-left: 0.5rem;
}

.custom-list-item:hover {
  color: #017df9;
}

.custom-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
  padding: 0.5rem 1rem;
}

.attack-title {
  width: 100%;
}

.btn-icon {
  width: 5%;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
}

.btn-icon:hover {
  background: none;
}

/* CustomAccordion.css */
.custom-accordion {
  .sub-accordion {
    color: white !important;
  }

  .accordion-button {
    color:#398ffe;
    background-color: #100e10;
    border: none;
    position: relative;
    font-weight: bolder;

    &:focus {
      box-shadow: none;
    }

    &.collapsed {
      .accordion-arrow {
        transform: rotate(-90deg);
        transition: transform 0.3s ease-in-out;
        margin-bottom: 5px;
        
      }
    }

    &:not(.collapsed) {
      color:#398ffe;
      background-color: #100e10;
      border-bottom: none;
      height: 60px;
      font-weight: bolder;

      .accordion-arrow {
        transition: transform 0.3s ease-in-out;
        transform: rotate(0);
      }
    }

    .accordion-arrow {
      transition: transform 0.3s ease;
      display: inline-block;
    }

    &::after {
      background-image: none !important;
      content: none;
      border: none;
    }
  }

  .accordion-item {
    background-color: #100e10;
    border: none;
  }

  .custom-accordion-body {
    width: 87.5% !important;
    border: 1px solid white;
    height: 40px;
    color: white !important;
    margin-left: 20px !important;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 10px 5px 5px 10px;
    border: 1px solid #726d6d;
    letter-spacing: 0.5px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit;
      background: linear-gradient(
        90deg,
        #f5f6f7 0%,
        #f4f4f5 15px,
        transparent 15px,
        transparent 100%
      );

      z-index: 0;
    }

    .sub-accordion {
      color: white !important;
    }

    .svg-white path {
      fill: white;
    }

    &.sub-body {
      display: flex;
      color: white !important;
      gap: 10px;
      cursor: pointer;
      align-items: center;

      .new-tag {
        background: #777676 !important;
        color: white;
        border-radius: 2px;
        padding: 0.25em 0.5em;
        font-size: 0.75em;
      }
    }
  }

  .accordion-body-main {
    margin-left: -23px;

    .sub-heading {
      color: #ffffff !important;
    }
  }

  .sub-body {
    width: 97% !important;
    color: white !important;
    cursor: pointer;
  }

  .accordion-body {
    background-color: #100e10;
    color: white;
    font-family: "Kodchasan";
    font-size: 0.75rem;
    padding: 5px 25px 5px 25px;
    border-radius: 4px;
    margin-bottom: 2px;
  }

  .svg-white path {
    fill: white;
  }
}

// sub
.custom-accordion-sub {
  .accordion-header {
    height: 50px !important;
  }

  .accordion-button {
    color: white;
    background-color: #100e10;
    border: none;
    position: relative;
    font-weight: 400;

    &:focus {
      box-shadow: none;
    }

    &.collapsed {
      .accordion-arrow {
        transform: rotate(-90deg);
        margin-bottom: 5px;
      }
    }

    &:not(.collapsed) {
      color: white;
      background-color: #100e10;
      border-bottom: none;
      font-weight: 400;

      .accordion-arrow {
        transform: rotate(0);
      }
    }

    .accordion-arrow {
      transition: transform 0.3s ease;
      display: inline-block;
    }

    &::after {
      background-image: none !important;
      content: none;
      border: none;
    }
  }

  .accordion-item {
    background-color: #100e10;
    border: none;
  }

  .custom-accordion-body {
    width: 100%;
    border: 1px solid white;
    height: 40px;
    color: white !important;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 10px 5px 5px 10px;
    letter-spacing: 0.5px;
    position: relative;
    border: 1px solid #726d6d;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit;
      background: linear-gradient(
        90deg,
        #f5f6f7 0%,
        #f4f4f5 15px,
        transparent 15px,
        transparent 100%
      );
      z-index: 0;
    }

    .sub-accordion {
      color: white !important;
    }

    .svg-white path {
      fill: white;
    }

    &.sub-body {
      display: flex;
      color: white !important;
      gap: 10px;
      cursor: pointer;
      align-items: center;

      .new-tag {
        background: #777676 !important;
        color: white;
        border-radius: 2px;
        padding: 0.25em 0.5em;
        font-size: 0.75em;
      }
    }
  }
  .menu-disabled {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit;
      background: linear-gradient(
        90deg,
        #f5f6f7 0%,
        #f4f4f5 15px,
        transparent 15px,
        transparent 100%
      );
      z-index: 0;
    }
    &:after {
      content: "";
      position: absolute;
      top: 17px;
      left: 5px;
      width: 5px;
      height: 5px;
      background: #726d6d;
      border-radius: 50%;
      z-index: 1;
    }
  }

  .accordion-body-main {
    margin-left: -23px;

    .sub-heading {
      color: #ffffff !important;
    }
  }

  .Error-icon {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .Loader-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    animation: round 4s linear infinite;
  }

  .warning-icon {
    position: absolute;
    right: 30px;
    top: 8px;
    background: #3b3b3e;
    border-radius: 4px;
    padding: 2px;
  }

  @keyframes round {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .sub-body {
    width: 97% !important;
    color: white !important;
    cursor: pointer;
  }

  .accordion-button:not(.collapsed) {
    margin-bottom: -5px;
    height: 45px;
  }

  .accordion-body {
    margin-top: 10px;
    background-color: #100e10;
    color: white;
    font-family: "Kodchasan";
    font-size: 0.75rem;
    // padding: 0px 25px 5px 25px;
    border-radius: 4px;
    // margin-bottom: 2px;
  }

  .svg-white path {
    fill: white;
  }
}

.accordion-button:not(.collapsed) {
  background-color: #100e10;
  border: none;
  color: white;
  box-shadow: none !important;
}

.no-border-accordion {
  .accordion-item,
  .accordion-button {
    border: none;
    background-color: transparent;
    color: inherit;

    &:not(.collapsed) {
      background-color: transparent;
    }
  }

  .custom-accordion-header {
    display: flex;
    align-items: center;
    background-color: #100e10;
    font-family: "Kodchasan";
    color: white;
  }

  .custom-accordion-body {
    padding: 0.5rem 1rem;
    border-top: none;
  }
}

.sub-body-active::before {
  // background: #398FFE;
  background: linear-gradient(
    90deg,
     #398FFE 0%,
     #398FFE 15px,
    transparent 15px,
    transparent 100%
  ) !important;
  background-color: #398FFE4D !important;
  border-top: 1px solid #398FFE4D !important;
  border-bottom: 1px solid #398FFE4D !important;
}

.sub-body-active {
  border: 1px solid rgba(61, 220, 151, 0.3) !important;
}

.sub-body-disabled::before {
  background: linear-gradient(
    90deg,
    #7c7c7c 0%,
    #7c7c7c 15px,
    transparent 15px,
    transparent 100%
  ) !important;
  background-color: #3a3a3a !important;
  border-top: 1px solid #2b2b2b !important;
  border-bottom: 1px solid #2b2b2b !important;
  z-index: 0;
}

.sub-body-disabled {
  border: 1px solid #2b2b2b !important;
  position: relative;
}
.sub-body-disabled span {
  position: relative !important;
  z-index: 1 !important;
  color: #8e8a8a !important;
}
.active-title {
  color: #3ddc97 !important;
  font-family: "Kodchasan" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.custom-child-header .accordion-button:not(.collapsed) {
  height: 57px;
}

.child-accordian-body {
  margin-left: -25px !important;
  margin-top: -13px !important;
}

.asset-load-container {
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  .asset-load-icon {
    position: absolute;
    right: 50%;
    top: 50%;
    animation: round 4s linear infinite;
    width: 40px;
    height: 40px;
  }
}

.asset-link {
  cursor: pointer;
  text-decoration: underline;
}
.asset-link:hover {
  color: #87c2f3 !important;
}

.war-room-accordian{
  .custom-accordion-body {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit;
      background: linear-gradient(
        90deg,
        #C5C5C5  0%,
        #C5C5C5 15px,
        transparent 15px,
        transparent 100%
      );
  
      z-index: 0;
    }
  
  
  }

    .custom-accordion-sub .accordion-button {
      color: #398ffe;
      background-color: #100e10;
      border: none;
      position: relative;
      font-weight: bolder;
  }

 
  

}
