:root {
  --font-size: 1.2rem;
  --bs-body-color: rgb(200, 200, 200);
  --color-secondary: rgb(255, 255, 255);
  --bs-dark-rgb: 255 255 255;
  --bs-body-bg: #000;
  --background-color-secondary: rgb(60, 60, 60);
  --logo-color: #185e99;
  --link-color: #398ffe !important;
  --blue: #185e99 !important;
  --white: #ffffff !important;
  --bs-btn-color: #fff;
  --bs-btn-bg: #185e99;
  --bs-btn-border-color: #14568b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3b67a0;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #185e99;
  --bs-btn-active-border-color: #14568b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #185e99;
  --bs-btn-disabled-border-color: #14568b;

  /* app-color-variables */
  --table-bg-color: #272728;
  --table-hover-color: #272728;
  --custom-black-color: #0a0a0e;
  --custom-table-row-selected-color: #4e4e55;
  --search-text-color: #a7a7b1;
  --secondary-badge-bg-color: rgb(61 61 64);
  --status-danger: #ff6155;
  --status-success: #3ddc97;
  --primary-bg-color: #0a0a0e00;
  --primary-border-color: #636363;
  --header-bg-color: #3e3e3e;
  --text-color-slategray: slategray;
  --secondary-text-color: #aaa;
  --tertiary-text-color: #ddd;
  --highlight-color: #bcf4de;
  --card-bg-gradient-start: rgba(90, 90, 91, 0);
  --card-bg-gradient-end: #5a5a5b;
  --tab-bg-color: #4a4a4a;
  --scrollbar-thumb-color: #888;
  --scrollbar-thumb-hover-color: #555;
  --dropdown-active-border-color: #3785ec;
  --disbaled-bg-color: #767272;
  --app-dark: #0e0e0e;
  --disable-link: #929292;
}
@font-face {
  font-family: 'Bank Gothic Pro';
  src: url('/src/assets/fonts/font-bold.woff2') format('woff2'),
       url('/src/assets/fonts/font-bold.woff') format('woff');
  font-weight: 700; 
  font-style: normal;
}

@font-face {
  font-family: 'Bank Gothic Pro';
  src: url('/src/assets/fonts/font-medium.woff2') format('woff2'),
       url('/src/assets/fonts/font-medium.woff') format('woff');
  font-weight: 500; 
  font-style: normal;
}

@font-face {
  font-family: 'Bank Gothic Pro';
  src: url('/src/assets/fonts/small.woff2') format('woff2'),
       url('/src/assets/fonts/small.woff') format('woff');
  font-weight: 300; 
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu",'Inter', "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Bank Gothic Pro", 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.btn {
  --bs-btn-font-size: 1.2rem;
}
.btn-primary {
  --bs-btn-bg: var(--logo-color);
  --bs-btn-border-color: var(--logo-color);
}
.btn-dark {
  --bs-btn-bg: var(--link-color);
  --bs-btn-border-color: var(--link-color);
}

.btn {
  padding: 10px;
  width: 100%;
}
.login-btn {
  width: 100%;
}

.form-control {
  background: #000;
  border: 2px solid #185e99;

  border-radius: 0.4rem;
  font-size: var(--font-size);
  margin: 20px 0;
  padding: 10px;
  width: 100%;
}

.news-item {
  padding: 0;
  background: #181717;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.news-item:hover {
  -webkit-box-shadow: 0 8px 0 #60abe1 !important;
  border-radius: 8px !important;
  box-shadow: 0 8px 0 #60abe1 !important;
}
.news-item img {
  height: 100%;
  padding: 0;
  /* opacity: 0.4; */
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.news-item:hover img {
  opacity: 0.3;
  transform: scale(1.5);
}

.news-item:hover .card-body {
  padding-top: 39px;
  background-color: transparent;
  transform: translate(2px, -39px);
  -webkit-transform: translate(2px, -39px);
  -moz-transform: translate(2px, -39px);
  -o-transform: translate(2px, -39px);
  -ms-transform: translate(2px, -39px);
}

.form-control:focus {
  border: 2px solid var(--logo-color);
  background: #000;
  color: var(--logo-color);
}

.figure-caption {
  font-size: 1.875em;
  color: #aaadaf;
  text-align: center;
}
.header-nav li:first-child img {
  width: 55px;
}
.header-nav li img {
  width: 50px;
}

.header-nav li:last-child img {
  width: 35px;
}

/* new css */
.text-custom {
  color: var(--color-secondary);
}

.text-custom:hover {
  color: var(--logo-color);
}

.content-loader {
  height: calc(-60px + 100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .news-item {
    -webkit-box-shadow: 0 8px 0 #60abe1 !important;
    border-radius: 8px !important;
    box-shadow: 0 8px 0 #60abe1 !important;
  }

  .header-nav li:first-child img {
    width: 50px;
  }
  .header-nav li img {
    width: 40px;
  }

  .header-nav li:last-child img {
    width: 35px;
  }
}

@media (min-width: 1300px) {
  .max-80 {
    max-width: 80%;
  }
}

.custom_dropdown > .dropdown {
  border: 1px solid white;
  background: black;
  color: white;
  border-radius: 10px;
  margin-top: 20px;
}

.custom_dropdown > .dropdown > .dropdown-toggle {
  height: 50px;
  margin: 0;
}

.custom_dropdown label {
  margin-top: 0px !important;
}

.Toastify__toast-body {
  font-family: "Kodchasan", sans-serif;
}
